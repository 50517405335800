<template>
  <v-container pa-5 fluid>
    <h2 class="mb-5">Usuários</h2>

    <v-card>
      <v-card-text>
        <v-toolbar rounded style="margin-bottom: 40px;" elevation="0">
          <v-toolbar-title>Filtros</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-text-field
            v-model="filtros.nome"
            hide-details
            placeholder="Nome do usuário"
            solo
            dense
          />
          <v-spacer></v-spacer>
          <v-btn color="primary" :loading="loadingPesquisar" @click="pesquisar">
            Pesquisar
          </v-btn>
        </v-toolbar>

        <v-card elevation="1">
          <v-toolbar flat color="white">
            <v-toolbar-title>Usuários</v-toolbar-title>
            <v-divider class="mx-2" inset vertical />
            <v-spacer />
            <v-btn color="primary" class="mb-2" outlined @click="novoUsuario">
              Novo Usuário
            </v-btn>
          </v-toolbar>
          <v-data-table
            :headers="headers"
            :items="usuarios"
            :options.sync="options"
            :server-items-length="totalUsuarios"
            :items-per-page="itemsPerPage"
            :loading="loading"
            class="elevation-1"
            loading-text="Carregando..."
          >
            <template slot="headerCell" slot-scope="{ header }">
              <span
                class="subheading font-weight-light text--darken-3"
                v-text="header.text"
              />
            </template>
            <template v-slot:body="{ items }" ma-5>
              <tbody>
                <tr v-for="item in items" :key="item.name">
                  <td>
                    <avatar :avatarUrl="item.fotoUrl" />
                  </td>
                  <td>{{ item.nome }}</td>
                  <td>{{ item.email }}</td>
                  <td>{{ item.roleLabel }}</td>
                  <td>{{ item.isAtivo ? "Ativo" : "Inativo" }}</td>
                  <td class="text-right">
                    <v-icon
                      class="mr-2"
                      title="Editar dados do usuário"
                      @click="editarUsuario(item)"
                      >mdi-pencil</v-icon
                    >
                    <v-icon
                      color="primary"
                      title="Excluir usuário"
                      @click="openDialogDelete(item)"
                      >mdi-delete</v-icon
                    >
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-card>
      </v-card-text>
    </v-card>
    <template v-if="dialogDelete">
      <v-row justify="center">
        <v-dialog v-model="dialogDelete" persistent max-width="700px">
          <v-card>
            <v-form
              ref="formDelete"
              v-model="valid"
              lazy-validation
              @submit.prevent="deleteUsuario"
            >
              <v-card-title>
                <span class="headline">Excluir Usuário</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col align="center" cols="12">
                      <span class="title">
                        Ao excluir esse usuário, ele não poderá mais entrar no
                        sistema?
                      </span>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="emailExcluido"
                        label="Email do usuário que será excluído*"
                        :rules="[rules.required]"
                        :hint="usuario.email"
                        persistent-hint
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
                <small class="error--text">*campos obrigatórios</small>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="btnCancelar" text @click="dialogDelete = false">
                  Cancelar
                </v-btn>
                <v-btn
                  :disabled="!valid"
                  color="primary"
                  type="submit"
                  :loading="loadingSalvar"
                >
                  <span style="color: white;">Excluir</span>
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
      </v-row>
    </template>

    <dialog-perfil-user
      v-if="dialog"
      :close-dialog="closeDialog"
      :usuario="usuario"
      :callback="salvar"
    />
  </v-container>
</template>

<script>
import Avatar from "../../components/avatar.vue";
import DialogPerfilUser from "../../components/dialog-perfil-user.vue";
import { UserService } from "../../services";
export default {
  components: { Avatar, DialogPerfilUser },

  data: () => ({
    valid: false,
    usuarios: [],
    roles: [],
    dialog: false,
    dialogSenha: false,
    dialogDelete: false,
    filtros: {
      nome: "",
      email: "",
      roleId: null
    },
    page: 1,
    pageCount: 0,
    itemsPerPage: 20,
    totalUsuarios: 0,
    options: {},
    headers: [
      { text: "Avatar", value: "fotoUrl" },
      { text: "Nome", value: "nome" },
      { text: "Email", value: "email" },
      { text: "Perfil", value: "roleLabel" },
      { text: "Situação", value: "isAtivo" },
      { text: "Ações", value: "", align: "right" }
    ],
    editedIndex: -1,
    usuario: {
      nome: "",
      email: "",
      roleId: "",
      roleLabel: "",
      fotoUrl: "",
      senha: ""
    },
    rules: {
      required: value => !!value || "Campo obrigatório.",
      confirm: value =>
        (!!value && this.novaSenha === value) ||
        "As duas senhas precisam ser iguais!",
      confirmDelete: value =>
        (!!value && this.usuario.email === value) ||
        "O endereço de email precisa ser igual!",
      email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Endereço de email inválido.";
      }
    },
    loadingFoto: false,
    loadingSalvar: false,
    loading: false,
    loadingPesquisar: false,
    formData: null,
    file: {},
    emailExcluido: "",
    flgAlterarSenha: false
  }),
  watch: {
    options: {
      handler() {
        this.paginar();
      },
      deep: true
    }
  },
  mounted() {
    this.reload();
  },
  methods: {
    reload() {
      this.paginar();
    },

    novoUsuario() {
      this.flgAlterarSenha = false;
      this.usuario = {
        nome: "",
        email: "",
        roleId: "",
        roleLabel: "",
        fotoUrl: ""
      };
      this.dialog = true;
    },

    editarUsuario(item) {
      this.flgAlterarSenha = false;
      this.usuario = item;
      this.dialog = true;
    },

    closeDialog() {
      this.dialog = false;
    },

    openDialogDelete(item) {
      this.emailExcluido = "";
      this.usuario = item;
      this.dialogDelete = true;
    },

    deleteUsuario() {
      if (this.$refs.formDelete.validate()) {
        if (this.usuario.email === this.emailExcluido) {
          this.loadingSalvar = true;
          UserService.delete(this.usuario.id)
            .then(response => {
              this.$root.showSucesso(response.data);
            })
            .catch(error => {
              if (error.status != 401) {
                this.$root.showErro(error.data);
              }
            })
            .finally(() => {
              this.loadingSalvar = false;
              this.dialogDelete = false;
              this.reload();
            });
        } else {
          this.$root.showErro(
            "O endereço de email, tem que ser igual ao endereço do usuário a ser excluído!"
          );
        }
      } else {
        this.$root.showErro("Todos os campos são obrigatórios");
      }
    },

    salvar() {
      this.closeDialog();
      this.reload();
    },

    uploadAvatar(event) {
      if (event && event.target && event.target.files.length > 0) {
        this.file = event.target.files[0];
        this.formData = new FormData();
        this.formData.append("file", this.file);
        this.formData.append("name", this.file.name);

        this.loadingFoto = true;
        UserService.uploadFoto(this.usuario.id, this.formData)
          .then(response => {
            this.usuario.fotoUrl = response.data;
          })
          .catch(() =>
            this.$root.showErro(
              "Ocorreu um erro ao enviar a foto do perfil, tente novamente mais tarde."
            )
          )
          .finally(() => {
            this.loadingFoto = false;
          });
      }
    },

    pesquisar() {
      this.loadingPesquisar = true;
      this.paginar();
    },

    paginar() {
      this.loading = true;
      const { page, itemsPerPage } = this.options;
      UserService.pagination(
        itemsPerPage,
        page ? page - 1 : 0,
        "nome",
        this.filtros
      )
        .then(response => {
          this.usuarios = response.data.content;
          this.totalUsuarios = response.data.totalElements;
        })
        .finally(() => {
          this.loadingPesquisar = false;
          this.loading = false;
        });
    },

    textEmailDelete() {
      return (
        '<span class="error--text title">' + this.usuario.email + "</span>"
      );
    },

    alterarSenha() {
      this.loading = true;
      UserService.updateSenha(this.usuario)
        .then(response => {
          this.$root.showSucesso(response.data);
        })
        .catch(error => {
          if (error.status != 401) {
            this.$root.showErro(error.data);
          }
        })
        .finally(() => {
          this.loading = false;
          this.flgAlterarSenha = false;
        });
    }
  }
};
</script>

<style></style>
